'use strict';

import { map } from 'lodash';
import Vue from 'vue';
import apiCalls from '../../api/post.js';
import { objectKeysToCamelCase, objectKeysToSnakeCase } from '../helpers/convertCase';

// The initial state
const State = {
    post: {
        id: null,
        userId: null,
        type: null,
        title: null,
        slug: null,
        content: null,
        status: 'published',
        createdAt: null,
        updatedAt: null,
        author: {},
        topics: [],
        comments: []
    },
    commentPagination: {
        currentPage: 0,
        pageCount: 1,
        totalItems: 0
    }
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    post: ({ post }) => post,
    comments: ({ post }) => post.comments,
    totalComments: ({ commentPagination }) => commentPagination.totalItems,
    commentsCurrentPage: ({ commentPagination }) => commentPagination.currentPage,
    commentsPageCount: ({ commentPagination }) => commentPagination.pageCount
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    publish({ commit }, post) {
        const clonePost = Object.assign({}, post);
        clonePost.topics = map(post.topics, 'id');
        return apiCalls.publish(objectKeysToSnakeCase(clonePost)).then((response) => {
            commit('populate', response.data);
        });
    },
    update({ commit }, post) {
        const clonePost = Object.assign({}, post);
        clonePost.topics = map(post.topics, 'id');
        return apiCalls.update(objectKeysToSnakeCase(clonePost)).then((response) => {
            commit('populate', response.data);
        });
    },
    delete({ commit }, post) {
        const clonePost = Object.assign({}, post);
        clonePost.topics = map(post.topics, 'id');
        return apiCalls.delete(objectKeysToSnakeCase(clonePost)).then(() => {
            commit('populate', {});
        });
    },
    get({ commit }, id) {
        return apiCalls.get(id).then((response) => {
            commit('populate', response.data);
        });
    },
    getComments({ commit }, { parent: post, pageNum }) {
        return apiCalls.getComments(post, pageNum).then((response) => {
            commit('populateComments', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    addComment({ commit }, { comment, parent: post }) {
        return apiCalls.addComment(comment, post).then((response) => {
            commit('addComment', response.data);
        });
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, post) {
        state.post = objectKeysToCamelCase(post);
        Vue.set(state.post, 'comments', []);
    },
    populateComments(state, comments) {
        // Reactivity workaround
        comments.forEach((comment) => {
            state.post.comments.push(objectKeysToCamelCase(comment));
        });
    },
    addComment(state, comment) {
        state.post.comments.unshift(objectKeysToCamelCase(comment));
    },
    setCurrentPage(state, page) {
        state.commentPagination.currentPage = page;
    },
    setPageCount(state, count) {
        state.commentPagination.pageCount = count;
    },
    setTotalItems(state, count) {
        state.commentPagination.totalItems = count;
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
