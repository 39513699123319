import Vue from 'vue';

export default {
    all(pageNum = 1) {
        return Vue.axios.get('post', { params: { page: pageNum } });
    },
    getPostsByUser(userId) {
        // eslint-disable-next-line camelcase
        return Vue.axios.get(`user/${userId}/posts`, { params: { per_page: 3 } });
    },
    search(search, pageNum = 1, perPage = 15) {
        // eslint-disable-next-line camelcase
        const params = Object.assign({}, { page: pageNum, per_page: perPage }, search);
        return Vue.axios.get('post/search', { params });
    }
};
