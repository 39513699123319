<template src="./avatar.html" />

<script>
'use strict';

import parseDate from 'date-fns/parse';
import getTime from 'date-fns/get_time';
import { mapGetters } from 'vuex';
import config from '../../../assets/js/config/config';

export default {
    props: {
        user: {
            type: Object,
            required: true,
            default: null
        },
        refreshToken: {
            required: false,
            default: ''
        }
    },
    computed: {
        ...mapGetters({ accessToken: 'auth/accessToken' }),
        url() {
            let url = `${this.baseUrl}/images/user/${this.user.id}/image/avatar?token=${this.accessToken}`;
            if (this.refreshToken && this.refreshToken.length) {
                url = url + `&${this.refreshToken}`;
            } else {
                url = url + `&${getTime(parseDate(this.user.updatedAt))}`;
            }
            return url;
        }
    },
    data() {
        return { baseUrl: config.api.url + '/v' + config.api.version };
    }
};

</script>
