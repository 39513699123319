<template src="./logout.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import Alert from '../../atoms/alert/alert.vue';

export default {
    components: { Alert },
    methods: {
        ...mapActions({ logout: 'auth/logout' })
    },
    computed: {
        ...mapGetters({ authorized: 'auth/authorized' })
    },
    created() {
        this.logout();
    }
};
</script>
