<template src="./user-teaser.html" />

<script>
'use strict';

import avatar from '../../atoms/avatar/avatar.vue';
import topicList from '../../molecules/topic-list/topic-list.vue';

export default {
    components: { avatar, topicList },
    props: {
        user: {}
    },
    computed: {
        location() {
            let location = '';
            if (this.user.city) {
                location = this.user.city;
                if (this.user.country) {
                    location += ', ';
                }
            }
            if (this.user.country) {
                location += this.user.country;
            }
            return location;
        }
    }
};

</script>
