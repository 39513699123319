<template src="./comment.html" />

<script>
'use strict';

import Avatar from '../../atoms/avatar/avatar.vue';

export default {
    components: { Avatar },
    props: ['content', 'author']
};
</script>
