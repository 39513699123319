<template src="./set-password.html" />

<script>
'use strict';

import passwordForm from '../../../patterns/organisms/password-form/password-form.vue';

export default {
    components: {
        passwordForm
    },
    props: ['token']
};
</script>
