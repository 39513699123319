<template src="./comment-list.html" />

<script>
'use strict';

import infiniteScroll from 'vue-infinite-scroll';
import scrollTo from '../../../assets/js/helpers/scrollTo';
import commentForm from '../../molecules/comment-form/comment-form.vue';
import Comment from '../../molecules/comment/comment.vue';
import Loader from '../../atoms/loader/loader.vue';

export default {
    components: { Comment, Loader, commentForm },
    directives: { infiniteScroll },
    props: ['parent', 'module'],
    data() {
        return { loading: false };
    },
    computed: {
        currentPage() {
            return this.$store.getters[`${this.module}/commentsCurrentPage`];
        },
        lastPage() {
            return this.$store.getters[`${this.module}/commentsPageCount`];
        },
        comments() {
            return this.$store.getters[`${this.module}/comments`];
        },
        commentsCount() {
            return this.$store.getters[`${this.module}/totalComments`];
        },
        disableInfiniteScroll() {
            return this.loading || this.currentPage === this.lastPage;
        }
    },
    watch: {
        // call again the method if the route changes
        $route: 'getComments'
    },
    created() {
        this.getComments();
    },
    methods: {
        getComments(page = 1) {
            this.loading = true;
            return this.$store.dispatch(
                `${this.module}/getComments`,
                { pageNum: page, parent: this.parent }
            ).then(() => {
                this.loading = false;
                this.$nextTick(() => {
                    if (this.$route.hash && this.$route.hash.includes('comment')) {
                        scrollTo(this.$route.hash);
                    }
                });
            });
        },
        loadMore() {
            this.getComments(this.currentPage + 1);
        }
    }
};
</script>
