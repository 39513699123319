// Libraries & helpers
import Vue from 'vue';
import Router from 'vue-router';
import Home from '../../patterns/pages/home/home.vue';
import Explore from '../../patterns/pages/explore/explore.vue';
import Logout from '../../patterns/pages/logout/logout.vue';
import Settings from '../../patterns/pages/settings/settings.vue';
import Styleguide from '../../patterns/pages/styleguide/styleguide.vue';
import AddPost from '../../patterns/pages/add-post/add-post.vue';
import Post from '../../patterns/pages/post/post.vue';
import Network from '../../patterns/pages/network/network.vue';
import PageNotFound from '../../patterns/pages/404/404.vue';
import Profile from '../../patterns/pages/profile/profile.vue';
import Learn from '../../patterns/pages/learn/learn.vue';
import Method from '../../patterns/pages/method/method.vue';
import Topic from '../../patterns/pages/topic/topic.vue';
import Help from '../../patterns/pages/help/help.vue';
import SetPassword from '../../patterns/pages/set-password/set-password.vue';
import EditPost from '../../patterns/pages/edit-post/edit-post.vue';
import ForgotPassword from '../../patterns/pages/forgot-password/forgot-password.vue';
import store from './store/index.js';

const online = process.env.APP_ONLINE;

// Use router
if (online) {
    Vue.use(Router);
}

const router = new Router({
    mode: 'history',
    linkActiveClass: 'link--active',
    routes: [
        {
            path: '/404',
            name: '404',
            component: PageNotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: { alternateLayout: true },
            beforeEnter: (to, from, next) => {
                if (store.getters['auth/authorized']) {
                    next({
                        name: 'explore'
                    });
                } else {
                    next();
                }
            }
        },
        {
            path: '/forgot-password/',
            name: 'forgotPassword',
            component: ForgotPassword,
            meta: { alternateLayout: true },
            props: true,
            beforeEnter: (to, from, next) => {
                if (store.getters['auth/authorized']) {
                    next({
                        name: 'settings'
                    });
                } else {
                    next();
                }
            }
        },
        {
            path: '/set-password/:token',
            name: 'setPassword',
            component: SetPassword,
            meta: { alternateLayout: true },
            props: true,
            beforeEnter: (to, from, next) => {
                if (store.getters['auth/authorized']) {
                    next({
                        name: 'settings'
                    });
                } else {
                    next();
                }
            }
        },
        {
            path: '/explore',
            name: 'explore',
            component: Explore,
            meta: { requiresAuth: true }
        },
        {
            path: '/explore/:slug/:id',
            name: 'post',
            component: Post,
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/explore/add',
            name: 'post.add',
            component: AddPost,
            meta: { requiresAuth: true }
        },
        {
            path: '/explore/edit/:slug/:id',
            name: 'post.edit',
            component: EditPost,
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/learn',
            name: 'learn',
            component: Learn,
            meta: { requiresAuth: true }
        },
        {
            path: '/learn/:slug/:id',
            name: 'method',
            component: Method,
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: { requiresAuth: true }
        },
        {
            path: '/styleguide',
            name: 'styleguide',
            component: Styleguide,
            meta: { requiresAuth: false }
        },
        {
            path: '/topic/:id',
            name: 'topic',
            component: Topic,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: { requiresAuth: true }
        },
        {
            path: '/profile/:id',
            name: 'profile',
            component: Profile,
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/network',
            name: 'network',
            component: Network,
            meta: { requiresAuth: true }
        },
        {
            path: '/help',
            name: 'help',
            component: Help,
            meta: { requiresAuth: true }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters['auth/authorized']) {
            next({
                name: 'home',
                query: { redirect: to.fullPath }
            });
        } else if (to.matched.some(record => record.name !== 'settings')) {
            // Check now if the profile is considered "complete", otherwise, people are locked to the edit page until this is resolved.
            if (!store.getters['auth/complete']) {
                next({
                    name: 'settings',
                    query: { redirect: to.fullPath }
                });
            }
        }
        next();
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;
