'use strict';

import { map } from 'lodash';
import apiCalls from '../../api/methods.js';
import { objectKeysToCamelCase } from '../helpers/convertCase';

// The initial state
const State = {
    methods: [],
    totalItems: 0,
    currentPage: 0,
    pageCount: 0
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    methods: ({ methods }) => methods,
    currentPage: ({ currentPage }) => currentPage,
    totalItems: ({ totalItems }) => totalItems,
    pageCount: ({ pageCount }) => pageCount
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    all({ commit }, pageNum) {
        return apiCalls.all(pageNum).then((response) => {
            commit('populate', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    search({ commit, rootState }, pageNum) {
        const searchObj = {};
        if (typeof rootState.search.search !== 'undefined' && rootState.search.search.length) {
            searchObj.q = rootState.search.search;
        }
        if (typeof rootState.search.filters !== 'undefined') {
            searchObj.topics = map(rootState.search.filters, 'id');
        }

        return apiCalls.search(searchObj, pageNum).then((response) => {
            commit('populate', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    additional({ commit }, pageNum) {
        return apiCalls.all(pageNum).then((response) => {
            commit('append', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    paginateSearch({ commit, rootState }, pageNum) {
        const searchObj = {};
        if (typeof rootState.search.search !== 'undefined' && rootState.search.search.length) {
            searchObj.q = rootState.search.search;
        }
        if (typeof rootState.search.filters !== 'undefined') {
            searchObj.filters = map(rootState.search.filters, 'id');
        }

        return apiCalls.search(searchObj, pageNum).then((response) => {
            commit('append', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    getMethodsForTopic({ commit }, topicId) {
        return apiCalls.search({ topics: [topicId] }, 1, 2).then((response) => {
            commit('populate', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, methods) {
        state.methods = [];
        methods.forEach((post) => {
            state.methods.push(objectKeysToCamelCase(post));
        });
    },
    append(state, methods) {
        methods.forEach((post) => {
            state.methods.push(objectKeysToCamelCase(post));
        });
    },
    setCurrentPage(state, page) {
        state.currentPage = page;
    },
    setPageCount(state, count) {
        state.pageCount = count;
    },
    setTotalItems(state, count) {
        state.totalItems = count;
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
