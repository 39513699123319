'use strict';

import { find } from 'lodash';
import apiCalls from '../../api/topics.js';
import { objectKeysToCamelCase } from '../helpers/convertCase';

// The initial state
const State = {
    topics: []
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    topics: ({ topics }) => topics,
    getById: ({ topics }) => function(id) {
        const category = find(topics, { topics: [{ id: parseInt(id, 10) }] });
        if (category && category.topics.length) {
            return find(category.topics, { id: parseInt(id, 10) });
        }
    }
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    all({ state, commit }) {
        if (state.topics.length === 0) {
            return apiCalls.all().then((response) => {
                commit('populate', response.data);
            });
        } else {
            return Promise.resolve(state.topics);
        }
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, topics) {
        state.topics = [];
        topics.forEach((topic) => {
            state.topics.push(objectKeysToCamelCase(topic));
        });
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
