<template src="./search.html" />

<script>
'use strict';

import { mapActions } from 'vuex';

export default {

    methods: {
        ...mapActions({ setSearch: 'search/setSearch' }),
        search() {
            this.setSearch(this.query);
            this.$emit('search');
            this.$gtm.trackEvent({
                category: 'search',
                action: 'search',
                label: this.query
            });
        }
    },
    computed: {
        query: {
            get() {
                return this.$store.getters['search/search'];
            },
            set(value) {
                this.setSearch(value);
            }
        }
    }
};
</script>
