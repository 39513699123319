export default {
    self: {
        domain: 'culturecannotwait.ganymede.digitalnatives.nl',
        url: 'https://culturecannotwait.ganymede.digitalnatives.nl'
    },
    api: {
        domain: 'api.culturecannotwait.ganymede.digitalnatives.nl',
        url: 'https://api.culturecannotwait.ganymede.digitalnatives.nl/api',
        version: '1.0'
    }
};
