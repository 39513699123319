<template src="./login-form.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import alert from '../../atoms/alert/alert.vue';

export default {
    components: { alert },
    computed: {
        ...mapGetters({ authorized: 'auth/authorized' })
    },
    methods: {
        ...mapActions({ login: 'auth/login' }),
        submitLogin() {
            this.loading = true;
            this.login({ emailAddress: this.email, password: this.password }).then(() => {
                this.loading = false;
                this.$router.push(this.$route.query.redirect || { name: 'explore' });
            }).catch(() => {
                this.error = true;
                this.loading = false;
            });
        }
    },
    data() {
        return {
            email: '',
            password: '',
            error: null,
            loading: false
        };
    }
};

</script>
