'use strict';

import Vue from 'vue';

// The initial state
const State = {
    popups: {}
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    popups: ({ popups }) => popups
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    add({ commit }, { id, message }) {
        return commit('add', { id, message });
    },
    dismiss({ commit }, id) {
        return commit('dismiss', id);
    }
};

// Mutations change the actual data in the state
const Mutations = {
    clearAll(state) {
        Vue.set(state.popups, []);
    },
    add(state, { id, message }) {
        Vue.set(state.popups, id, message);
    },
    dismiss(state, id) {
        Vue.delete(state.popups, id);
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
