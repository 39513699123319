<template src="./file-preview.html" />

<script>
'use strict';

import { mapGetters } from 'vuex';
import mime from 'mime';
import filesize from 'filesize';
import config from '../../../assets/js/config/config.js';

export default {
    computed: {
        ...mapGetters({ accessToken: 'auth/accessToken' }),
        type() {
            return mime.getExtension(this.file.type);
        },
        size() {
            return filesize(this.file.size);
        },
        downloadUrl() {
            return `${config.api.url}/v${config.api.version}/file/${this.file.id}?token=${this.accessToken}`;
        }
    },
    props: {
        file: {}
    }
};
</script>
