<template src="./alert.html" />

<script>
'use strict';

export default {
    props: {
        message: {
            type: String,
            default: ''
        },
        alertType: {
            type: String,
            default: ''
        }
    },
    data() {
        return { baseClass: 'alert' };
    },
    computed: {
        classes() {
            return `${this.baseClass}${typeof this.alertType !== 'undefined' ? ' ' + this.baseClass + '--' + this.alertType : ''}`;
        }
    }
};

</script>
