export default {
    self: {
        domain: 'cer-platform.test',
        url: 'https://cer-platform.test:3000'
    },
    api: {
        domain: 'api.cer-platform.test',
        url: 'https://api.cer-platform.test/api',
        version: '1.0'
    }
};
