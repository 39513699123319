<template src="./404.html" />

<script>
'use strict';

import alert from '../../atoms/alert/alert.vue';

export default {
    components: { alert }
};
</script>
