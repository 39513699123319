<template src="./topic-people-list.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import Avatar from '../../atoms/avatar/avatar.vue';
import UserTeaserSmall from '../../organisms/user-teaser-small/user-teaser-small.vue';
import Loader from '../../atoms/loader/loader.vue';

export default {
    components: { Avatar, Loader, UserTeaserSmall },
    methods: {
        ...mapActions({ getPeople: 'users/getUsersForTopic', getMorePeople: 'users/getAdditionalUsersForTopic' }),
        doGetPeople() {
            this.loading = true;
            // @FIXME missing API functionality, cannot get specific "Ask me about" users, this currently returns
            // users with either ask- or tell me about topics
            this.getPeople(this.topic.id).then(() => {
                this.loading = false;
            });
        },
        loadMore() {
            this.loadingMore = true;
            this.getMorePeople({ topicId: this.topic.id, pageNum: this.currentPage + 1 }).then(() => {
                this.loadingMore = false;
            });
        }
    },
    computed: {
        ...mapGetters({ people: 'users/users', currentPage: 'users/currentPage', pageCount: 'users/pageCount' }),
        showLoadMore() {
            return this.pageCount > this.currentPage;
        }
    },
    created() {
        this.doGetPeople();
    },
    props: ['topic'],
    data() {
        return { loading: false, loadingMore: false };
    }
};
</script>
