<template src="./post.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import parseDate from 'date-fns/parse';
import format from 'date-fns/format';
import alert from '../../atoms/alert/alert.vue';
import loader from '../../atoms/loader/loader.vue';
import backButton from '../../atoms/back-button/back-button.vue';
import avatar from '../../atoms/avatar/avatar.vue';
import topicList from '../../molecules/topic-list/topic-list.vue';
import commentList from '../../organisms/comment-list/comment-list.vue';
import config from '../../../assets/js/config/config';

export default {
    components: { alert, backButton, loader, avatar, topicList, commentList },
    props: ['slug', 'id'],

    methods: {
        ...mapActions({
            getPost: 'post/get',
            delete: 'post/delete'
        }),
        fetchData() {
            this.loading = true;
            this.getPost(this.id).then(() => {
                this.loading = false;
            }).catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
        }
    },
    computed: {
        ...mapGetters({ post: 'post/post', user: 'auth/me' }),
        dateFormatted() {
            return format(parseDate(this.post.createdAt), config.dateFormat);
        },
        userIsAuthor() {
            if (this.post.author && this.post.author.id) {
                return this.post.author.id === this.user.id;
            }

            return false;
        }
    },
    data() {
        return {
            loading: false,
            alert: {}
        };
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
