import Vue from 'vue';

export default {
    publish(post) {
        post.status = 'published';
        return Vue.axios.post('post/publish', post);
    },
    get(id) {
        return Vue.axios.get(`post/${id}`);
    },
    addComment(comment, post) {
        return Vue.axios.post(`post/${post.id}/comment`, comment);
    },
    getComments(post, pageNum) {
        return Vue.axios.get(`post/${post.id}/comments`, { params: { page: pageNum } });
    },
    update(post) {
        return Vue.axios.put(`post/${post.id}`, post);
    },
    delete(post) {
        return Vue.axios.delete(`post/${post.id}`);
    }
};
