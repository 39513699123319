const env = process.env.APP_ENV || 'local';

const config = {
    bugsnagId: 'ddb5dbe843edcb9883fd87aaa9df0695',
    dateFormat: 'DD-MM-YYYY',
    dateTimeFormat: 'DD-MM-YYYY H:i',
    gtmId: 'GTM-M5858QC',
    appOnline: process.env.APP_ONLINE || true,
};

const localizedConfigs = {
    production: require('./production').default,
    staging: require('./staging').default,
    development: require('./development').default
};

const localizedConfig = localizedConfigs[env];

export default Object.assign(config, localizedConfig);
