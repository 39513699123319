<template src="./post-teaser.html" />

<script>
'use strict';

import { format } from 'date-fns';
import avatar from '../../atoms/avatar/avatar.vue';
import commentBalloon from '../../atoms/comment-balloon/comment-balloon.vue';
import config from '../../../assets/js/config/config.js';

export default {
    components: { avatar, commentBalloon },
    props: {
        post: {
            type: Object,
            default: () => {}
        },
        extraClasses: {
            type: String,
            default: ''
        }
    },
    computed: {
        classes() {
            return 'post-teaser--' + this.post.type.replace('_', '-');
        },
        allClasses() {
            return `${this.classes}${this.extraClasses ? ' ' + this.extraClasses : ''}`;
        },
        creationDate() {
            return format(this.post.createdAt, config.dateFormat);
        },
        shortenedTitle() {
            return this.post.title.length > 75 ? `${this.post.title.substring(0, 75)}...` : this.post.title;
        }
    }
};

</script>
