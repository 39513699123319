'use strict';

import { map, cloneDeep } from 'lodash';
import apiCalls from '../../api/user.js';
import { objectKeysToCamelCase, objectKeysToSnakeCase } from '../helpers/convertCase';

// The initial state
export const State = {
    user: {
        id: null,
        name: null,
        email: null,
        publicEmail: null,
        whatsapp: null,
        twitter: null,
        createdAt: null,
        updatedAt: null,
        shortIntro: null,
        bio: null,
        image: null,
        title: null,
        city: null,
        country: null,
        phone: null,
        notes: null,
        profileComplete: false,
        askMeAbout: [],
        tellMeAbout: [],
        notificationPreferences: {
            emergencies: true,
            commentAuthoredPosts: true,
            commentRepliedPost: true,
            newPostAma: true,
            newPostTma: true,
            newMembers: true
        }
    }
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    user: ({ user }) => user
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    get({ commit }, id) {
        return apiCalls.get(id).then((response) => {
            commit('populate', response.data);
        });
    },
    save({ commit }, data) {
        const apiData = cloneDeep(data);
        apiData.image = null;
        apiData.askMeAbout = map(apiData.askMeAbout, 'id');
        apiData.tellMeAbout = map(apiData.tellMeAbout, 'id');
        return apiCalls.save(objectKeysToSnakeCase(apiData)).then((response) => {
            commit('auth/setCurrentUser', response.data, { root: true });
        });
    },
    remove({ commit }) {
        return apiCalls.remove().then((response) => {
            commit('auth/deauthorize', response.data, { root: true });
        });
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, user) {
        state.user = objectKeysToCamelCase(user);
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
