<template src="./user-teaser-small.html" />

<script>
'use strict';

import avatar from '../../atoms/avatar/avatar.vue';

export default {
    components: { avatar },
    props: {
        user: {}
    }
};

</script>
