export default {
    self: {
        domain: 'culturecannotwait.net',
        url: 'https://culturecannotwait.net'
    },
    api: {
        domain: 'api.culturecannotwait.net',
        url: 'https://api.culturecannotwait.net/api',
        version: '1.0'
    }
};
