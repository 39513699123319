<template src="./navi-avatar.html" />

<script>
'use strict';

import { mapGetters } from 'vuex';
import avatar from '../../atoms/avatar/avatar.vue';

export default {
    components: { avatar },
    computed: {
        ...mapGetters({ user: 'auth/me' }),
        firstName() {
            return this.user.name.split(' ', 1)[0];
        }
    },
    methods: {
        toggleMenu() {
            this.toggled = !this.toggled;
        }
    },
    data() {
        return { toggled: false };
    }
};

</script>
