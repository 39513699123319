import Vue from 'vue';

export default {
    all(pageNum = 1) {
        return Vue.axios.get('method', { params: { page: pageNum } });
    },
    search(search, pageNum = 1, perPage = 15) {
        // eslint-disable-next-line camelcase
        const params = Object.assign({}, { page: pageNum, per_page: perPage }, search);
        return Vue.axios.get('method/search', { params });
    }
};
