import { forEach, isArray, camelCase, snakeCase, isPlainObject } from 'lodash';

export function objectKeysToCamelCase(object) {
    const camelCaseObject = isArray(object) ? [] : {};
    forEach(
        object,
        function(value, key) {
            if (isPlainObject(value) || isArray(value)) { // checks that a value is a plain object or an array - for recursive key conversion
                value = objectKeysToCamelCase(value); // recursively update keys of any values that are also objects
            }
            camelCaseObject[camelCase(key)] = value;
        }
    );
    return camelCaseObject;
}

export function objectKeysToSnakeCase(object) {
    const snakeCaseObject = isArray(object) ? [] : {};
    forEach(
        object,
        function(value, key) {
            if (isPlainObject(value) || isArray(value)) { // checks that a value is a plain object or an array - for recursive key conversion
                value = objectKeysToSnakeCase(value); // recursively update keys of any values that are also objects
            }
            snakeCaseObject[snakeCase(key)] = value;
        }
    );
    return snakeCaseObject;
}
