<template src="./loader.html" />

<script>
'use strict';

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
    components: { ScaleLoader },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
