'use strict';

import Axios from 'axios';
import config from '../config/config.js';

const axios = Axios.create({
    baseURL: config.api.url + '/v' + config.api.version + '/',
    timeout: 600000
});

export default axios;
export function init(Vue) {
    const token = Vue.$store.getters['auth/accessToken'];
    if (token) {
        Vue.axios.defaults.headers.common = Object.assign({}, Vue.axios.defaults.headers.common, {
            Authorization: `Bearer ${token}`
        });
    }

    // Add a 401 response interceptor
    Vue.axios.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        if (error.response && error.response.status === 401) {
            if (!error.config.url.includes('auth/login') && !error.config.url.includes('auth/logout') && !error.config.url.includes('auth/refresh') &&
            !error.config.url.includes('auth/checkPasswordToken') && !error.config.url.includes('auth/setPasswordByToken') && !error.config.url.includes('auth/requestPasswordLink')) {
                // Attempt to refresh the token and re-try what we were doing
                if (error.config && !error.config.__isRetryRequest) {
                    return Vue.$store.dispatch('auth/refresh').then(() => {
                        error.config.__isRetryRequest = true;
                        // Set the refreshed token for a retry of the same request
                        error.config.headers = Object.assign({}, error.config.headers, {
                            Authorization: `Bearer ${Vue.$store.getters['auth/accessToken']}`
                        });
                        return axios(error.config);
                    });
                } else {
                    Vue.$store.commit('auth/deauthorize');
                    Vue.$router.push('/');
                }
            }
        }
        return Promise.reject(error);
    });
}
