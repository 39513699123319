<template src="./edit-post.html" />

<script>
'use strict';

import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { component as Ckeditor } from 'vue-ckeditor5';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import apiCalls from '../../../assets/js/api/imageUpload';
import loader from '../../atoms/loader/loader.vue';

import Alert from '../../atoms/alert/alert.vue';
import RadioButton from '../../molecules/radio-button/radio-button.vue';
import BackButton from '../../atoms/back-button/back-button.vue';

export default {
    components: {
        Alert, RadioButton, BackButton, Multiselect, Ckeditor, loader
    },
    props: ['slug', 'id'],
    computed: {
        ...mapGetters({
            topics: 'topics/topics',
            accessToken: 'auth/accessToken',
            post: 'post/post'
        })
    },
    methods: {
        ...mapActions({
            update: 'post/update',
            delete: 'post/delete',
            getTopics: 'topics/all',
            getPost: 'post/get'
        }),

        deletePost() {
            if (!confirm('Are you sure you want to delete this post? This cannot be undone!')) {
                return;
            }

            this.deleting = true;

            this.delete(this.post).then(() => {
                this.alert = { type: 'success', message: 'Post deleted successfully' };
                setTimeout(() => {
                    this.$router.push({ name: 'explore' });
                }, 2000);
            }).catch(() => {
                this.alert = { type: 'error', message: 'Unable to delete your post, please try again!' };
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
            });
        },
        updatePost() {
            this.update(this.post).then(() => {
                this.alert = { type: 'success', message: 'Post updated successfully' };
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
            }).catch(() => {
                this.alert = { type: 'error', message: 'Unable to update your post, please try again!' };
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
            });
        },
        fetchData() {
            this.loading = true;
            const self = this;
            this.getPost(this.id).then(() => {
                this.editPost = this.post;
                self.loading = false;
            }).catch(({ response }) => {
                if (response.status === 404) {
                    self.$router.replace({ name: '404' });
                }
            });
        }
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        this.getTopics();
        this.fetchData();
    },
    data() {
        return {
            editPost: this.post,
            editors: {
                inline: InlineEditor
            },
            editorConfig: {
                toolbar: ['bold', 'bulletedList', 'numberedList', 'link', 'mediaembed', 'imageupload'],
                mediaEmbed: {
                    previewsInData: true,
                    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook']
                }
            },
            ckEditorUploadAdapter(fileLoader) {
                this.loader = fileLoader;
                this.upload = () => {
                    return this.loader.file.then(file => new Promise(() => {
                        const body = new FormData();
                        body.append('file', file);

                        return apiCalls.upload(body)
                            .then(response => response.data)
                            .then(url => ({
                                default: url
                            }))
                            .catch((error) => {
                                if (error.response.data.errors.file) {
                                    // Throw an error so CKeditors default catch() on this promise will show the error from the backend.
                                    throw error.response.data.errors.file;
                                }
                            });
                    }));
                };
            },
            alert: {},
            loading: false,
            deleting: false
        };
    }
};
</script>
