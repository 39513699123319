<template>
    <div class="comment-balloon">
        <slot />
    </div>
</template>

<script>
'use strict';

export default {

};
</script>
