<template src="./password-form.html" />

<script>
'use strict';

import alert from '../../atoms/alert/alert.vue';
import authApi from '../../../assets/js/api/auth';

export default {
    components: { alert },
    props: ['token'],
    data() {
        return {
            password: null,
            error: ''
        };
    },
    created() {
        this.checkToken();
    },
    methods: {
        checkToken() {
            authApi.check(this.token).catch(() => {
                this.error = 'The URL provided is no longer valid to set a password. Please try again.';
            });
        },
        submitPassword() {
            authApi.setPassword(this.password, this.token).then(() => {
                this.$gtm.trackEvent({
                    category: 'user',
                    action: 'set-password',
                    label: 'New password set'
                });
                this.$router.push({ name: 'home' });
            }).catch((error) => {
                this.error = 'Something went wrong, please try again';
                if (error.response.data.errors) {
                    this.error = error.response.data.errors.password[0];
                }
            });
        }
    }
};

</script>
