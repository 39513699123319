<template src="./add-post.html" />

<script>
'use strict';

import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { component as Ckeditor } from 'vue-ckeditor5';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import apiCalls from '../../../assets/js/api/imageUpload';

import Alert from '../../atoms/alert/alert.vue';
import RadioButton from '../../molecules/radio-button/radio-button.vue';
import BackButton from '../../atoms/back-button/back-button.vue';

export default {
    components: {
        Alert, RadioButton, BackButton, Multiselect, Ckeditor
    },
    computed: {
        ...mapGetters({
            topics: 'topics/topics',
            accessToken: 'auth/accessToken'
        })
    },
    methods: {
        ...mapActions({
            publish: 'post/publish',
            getTopics: 'topics/all'
        }),
        clear() {
            this.$set(this.post, 'topics', []);
            this.$set(this.post, 'title', '');
            this.$set(this.post, 'content', '');
        },
        publishPost() {
            this.publish(this.post).then(() => {
                this.alert = { type: 'success', message: 'Posted successfully' };
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
                this.clear();
                this.$gtm.trackEvent({
                    category: 'post',
                    action: 'new-post',
                    label: this.post.type
                });
            }).catch(() => {
                this.alert = { type: 'error', message: 'Unable to publish your post, please try again!' };
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
            });
        }
    },
    created() {
        this.getTopics();
    },
    data() {
        return {
            editors: {
                inline: InlineEditor
            },
            editorConfig: {
                toolbar: ['bold', 'bulletedList', 'numberedList', 'link', 'mediaembed', 'imageupload'],
                mediaEmbed: {
                    previewsInData: true,
                    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook']
                }
            },
            ckEditorUploadAdapter(loader) {
                this.loader = loader;
                this.upload = () => {
                    return this.loader.file
                        .then((file) => {
                            const body = new FormData();
                            console.log(file);
                            body.append('file', file);
                            return apiCalls.upload(body)
                                .then(response => response.data)
                                .then(url => ({
                                    default: url
                                }))
                                .catch((error) => {
                                    if (error.response.data.errors.file) {
                                        // Throw an error so CKeditors default catch() on this promise will show the error from the backend.
                                        throw error.response.data.errors.file;
                                    }
                                });
                        });
                };
            },
            post: {
                type: 'update',
                title: '',
                content: '',
                topics: []
            },
            alert: {}
        };
    }
};
</script>
