export default function(selector) {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

    const elem = document.querySelector(selector);

    if (elem) {
        if (isSmoothScrollSupported) {
            elem.scrollIntoView({ behavior: 'smooth' });
        } else {
            elem.scrollIntoView(false);
        }
    }
}
