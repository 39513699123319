'use strict';

// Main dependencies
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import user from './modules/user';
import users from './modules/users';
import post from './modules/post';
import posts from './modules/posts';
import topics from './modules/topics';
import search from './modules/search';
import methods from './modules/methods';
import method from './modules/method';
import page from './modules/page';
import popup from './modules/popup';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth, user, users, post, posts, topics, search, methods, method, page, popup
    },
    plugins: [
        createPersistedState({
            paths: ['auth']
        })
    ],
    strict: debug
});
