<template src="./home.html" />

<script>
'use strict';

import loginForm from '../../../patterns/organisms/login-form/login-form.vue';

export default {
    /* vuex: {
            getters: {
                authenticated,
                cards
            },
            actions: {
                getcards, addMessage
            }
        }, */
    components: {
        loginForm
    }
};
</script>
