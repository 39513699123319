<template src="./layout.html" />

<script>
'use strict';
import { mapGetters } from 'vuex';
import navigation from './patterns/organisms/navigation/navigation.vue';
import { init as apiInit } from './assets/js/api/api';
import cookieBar from './patterns/organisms/cookie-bar/cookie-bar.vue';
import popups from './patterns/atoms/popups/popups.vue';

export default {
    components: {
        navigation, cookieBar, popups
    },

    data() {
        return {
            online: process.env.APP_ONLINE,
        };
    },

    computed: {
        alternateLayout() {
            return this.$route ? this.$route.meta.alternateLayout : null;
        }
    },
    methods: {
        ...mapGetters({ authorized: 'auth/authorized' })
    },
    // eslint-disable-next-line vue/order-in-components
    created() {
        apiInit(this);
        // Refresh and re-check user data on page refresh
        if (this.authorized() === true) {
            // Check token isn't expired
            this.$store.dispatch('auth/check');
            if (this.authorized() === true) {
                // Refresh user data
                this.$store.dispatch('auth/getCurrentUser');
            }
        }
    }
};
</script>
