<template src="./radio-button.html" />

<script>
'use strict';

export default {
    model: {
        prop: 'radioModel'
    },
    props: ['name', 'label', 'subLabel', 'value', 'radioModel'],
    data() {
        return {
            inputModel: this.radioModel
        };
    },
    watch: {
        radioModel(newValue) {
            this.inputModel = newValue;
        }
    },
    methods: {
        inputChange() {
            this.$emit('input', this.inputModel);
        }
    }
};
</script>
