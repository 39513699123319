<template src="./topic-method-list.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import Loader from '../../atoms/loader/loader.vue';
import MethodTeaser from '../../organisms/method-teaser/method-teaser.vue';

export default {
    components: { Loader, MethodTeaser },
    methods: {
        ...mapActions({ getMethods: 'methods/getMethodsForTopic' }),
        doGetMethods() {
            this.loading = true;
            this.getMethods(this.topic.id).then(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapGetters({ methods: 'methods/methods' })
    },
    created() {
        this.doGetMethods();
    },
    props: ['topic'],
    data() {
        return { loading: false };
    }
};
</script>
