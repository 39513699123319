<template src="./method-teaser.html" />

<script>
'use strict';

export default {
    props: ['method'],
    computed: {
        image() {
            return this.$store.getters['method/image'](this.method.id);
        }
    }
};

</script>
