<template src="./settings.html" />

<script>
'use strict';

import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import getTime from 'date-fns/get_time';
import Checkbox from '../../atoms/checkbox/checkbox.vue';
import Alert from '../../atoms/alert/alert.vue';
import Avatar from '../../atoms/avatar/avatar.vue';
import stripHtml from '../../../assets/js/store/helpers/stripHtml';

export default {
    components: {
        Alert, Avatar, Multiselect, Checkbox
    },
    computed: {
        ...mapGetters({ profile: 'auth/me', topics: 'topics/topics' })
    },
    methods: {
        ...mapActions({ remove: 'user/remove', getTopics: 'topics/all', save: 'user/save', setImage: 'auth/setImage' }),
        removeAndConfirm() {
            const c = confirm('Your existing posts and comments will be anonimized. We cannot recover your account after you have removed it. Are you sure you want to remove your account?');
            if (c) {
                this.remove().then(() => {
                    alert('Your account was removed successfully. We\'re sorry to see you go!');
                    this.$router.push({ name: 'home' });
                });
            }
        },
        imageUpload() {
            const files = this.$refs.file.files[0];
            if (typeof files === 'undefined') {
                return;
            }
            const data = new FormData();

            // for single file
            data.append('image', files);
            this.$http.post(`user/${this.profile.id}/avatar-upload`, data)
                .then((response) => {
                    this.avatarRefreshToken = getTime(new Date());
                    this.setImage(response.data);
                }).catch(() => {
                    this.alert.type = 'error';
                    this.alert.message = 'Something went wrong uploading your picture, please try again or use another picture.';
                    document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
                });
        },
        fileDialog() {
            this.$refs.file.click();
        },
        doSave() {
            const wasImcomplete = !this.profile.profileComplete;
            this.loading = true;
            this.save(this.formData).then(() => {
                this.loading = false;
                this.alert.type = 'success';
                this.alert.message = 'Successfully saved your profile!';
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
                if (wasImcomplete && this.profile.profileComplete) {
                    this.$gtm.trackEvent({
                        category: 'user',
                        action: 'profile-completed',
                        label: 'Profile completed'
                    });
                }
            }).catch(() => {
                this.loading = false;
                this.alert.type = 'error';
                this.alert.message = 'Oops, something went wrong! Please try again.';
                document.querySelectorAll('.layout__main')[0].scrollTo(0, 0);
            });
        }
    },
    created() {
        this.getTopics();
        this.formData = { ...this.profile };
        this.formData.shortIntro = stripHtml(this.formData.shortIntro);
        this.formData.bio = stripHtml(this.formData.bio);
        if (this.profile.profileComplete === false) {
            this.alert.type = 'error';
            this.alert.message = 'Hi there! We\'ve noticed your profile isn\'t complete yet. Could you fill in all the required fields? After you\'ve completed your profile you can browse the rest of the platform!';
        }
    },
    data() {
        return {
            someArray: [],
            formData: {},
            avatarRefreshToken: null,
            alert: { type: '', message: '' },
            loading: false
        };
    }
};
</script>
