import Vue from 'vue';

export default {
    get(id) {
        return Vue.axios.get(`method/${id}`);
    },
    addComment(comment, method) {
        return Vue.axios.post(`method/${method.id}/comment`, comment);
    },
    getComments(method, pageNum) {
        return Vue.axios.get(`method/${method.id}/comments`, { params: { page: pageNum } });
    }
};
