import Vue from 'vue';

export default {
    get(id) {
        return Vue.axios.get(`user/${id}`);
    },
    save(data) {
        return Vue.axios.put(`user/${data.id}`, data);
    },
    me() {
        return Vue.axios.get('user/me');
    },
    // Remove the logged-in user (based on JWT, cannot remove another user!)
    remove() {
        // JWT is passed via Bearer header, so no need to pass it to the request by hand (see api.js)
        return Vue.axios.delete('user/delete');
    }
};
