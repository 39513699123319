<template src="./method.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import parseDate from 'date-fns/parse';
import getTime from 'date-fns/get_time';
import format from 'date-fns/format';
import loader from '../../atoms/loader/loader.vue';
import backButton from '../../atoms/back-button/back-button.vue';
import avatar from '../../atoms/avatar/avatar.vue';
import topicList from '../../molecules/topic-list/topic-list.vue';
import commentList from '../../organisms/comment-list/comment-list.vue';
import filePreview from '../../molecules/file-preview/file-preview.vue';
import config from '../../../assets/js/config/config';

export default {
    components: { backButton, loader, avatar, topicList, commentList, filePreview },
    props: ['slug', 'id'],
    methods: {
        ...mapActions({ getMethod: 'method/get' }),
        fetchData() {
            this.loading = true;
            this.getMethod(this.id).then(() => {
                this.loading = false;
            }).catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
        }
    },
    computed: {
        ...mapGetters({ method: 'method/method' }),
        dateFormatted() {
            return format(parseDate(this.method.createdAt), config.dateFormat);
        },
        image() {
            return `${this.$store.getters['method/image'](this.method.id, 'header')}&${getTime(parseDate(this.method.updatedAt))}`;
        }
    },
    data() {
        return {
            loading: false
        };
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
