'use strict';

import apiCalls from '../../api/page.js';
import { objectKeysToCamelCase } from '../helpers/convertCase';

// The initial state
const State = {
    page: {
        id: null,
        userId: null,
        title: null,
        slug: null,
        content: null,
        status: 'published',
        createdAt: null,
        updatedAt: null
    }
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    page: ({ page }) => page
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    getBySlug({ commit }, slug) {
        return apiCalls.get(slug).then((response) => {
            commit('populate', response.data);
        });
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, page) {
        state.page = objectKeysToCamelCase(page);
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
