<template src="./topic.html" />

<script>
'use strict';

import { mapActions } from 'vuex';
import topicPeopleList from '../../organisms/topic-people-list/topic-people-list.vue';
import topicMethodList from '../../organisms/topic-method-list/topic-method-list.vue';
import topicPostList from '../../organisms/topic-post-list/topic-post-list.vue';

export default {
    computed: {
        topic() {
            if (this.id !== null) {
                return this.$store.getters['topics/getById'](this.id);
            }
            return null;
        }
    },
    methods: {
        ...mapActions({ getTopics: 'topics/all' })
    },
    components: {
        topicPeopleList, topicMethodList, topicPostList
    },
    props: {
        id: null
    },
    created() {
        this.getTopics();
    }
};
</script>
