<template src="./topic-post-list.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import Loader from '../../atoms/loader/loader.vue';
import PostTeaser from '../../organisms/post-teaser/post-teaser.vue';

export default {
    components: { Loader, PostTeaser },
    methods: {
        ...mapActions({ getPosts: 'posts/getPostsForTopic' }),
        doGetPosts() {
            this.loading = true;
            this.getPosts(this.topic.id).then(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapGetters({ posts: 'posts/posts' })
    },
    created() {
        this.doGetPosts();
    },
    props: ['topic'],
    data() {
        return { loading: false };
    }
};
</script>
