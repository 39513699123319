<template src="./forgot-password.html" />

<script>
'use strict';

import forgotPasswordForm from '../../../patterns/organisms/forgot-password-form/forgot-password-form.vue';

export default {
    components: {
        forgotPasswordForm
    }
};
</script>
