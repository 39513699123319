<template src="./back-button.html" />

<script>
'use strict';

export default {
    props: ['to', 'title', 'params']
};

</script>
