import Vue from 'vue';

export default {
    // Log-in the current user, returns a JWT
    login(emailAddress, password) {
        return Vue.axios.post('auth/login', {
            email: emailAddress,
            password
        });
    },
    // Invalidate the JWT
    logout() {
        // JWT is passed via Bearer header, so no need to pass it to the request by hand (see api.js)
        return Vue.axios.post('auth/logout');
    },
    // We can refresh an expired token for two weeks.
    refresh() {
        return Vue.axios.post('auth/refresh');
    },
    // We can refresh an expired token for two weeks.
    check(token) {
        return Vue.axios.post('auth/checkPasswordToken', { token });
    },
    setPassword(password, token) {
        return Vue.axios.put('auth/setPasswordByToken', { token, password });
    },
    requestPasswordLink(email) {
        return Vue.axios.put('auth/requestPasswordLink', { email });
    }
};
