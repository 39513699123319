<template src="./topic-picker.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import { forEach } from 'lodash';

export default {
    methods: {
        ...mapActions({ getTopics: 'topics/all', addFilter: 'search/addFilter', removeFilter: 'search/removeFilter' }),
        fetchData() {
            this.loading = true;
            this.getTopics().then(() => {
                this.loading = false;
            });
        },
        toggle() {
            this.toggled = this.toggled === false;
        },
        toggleCategory(index) {
            this.$set(this.isToggled, index, typeof this.isToggled[index] === 'undefined' || this.isToggled[index] === false);
        },
        toggleFilter(topic) {
            if (this.filters[topic.id]) {
                this.removeFilter(topic);
            } else {
                this.addFilter(topic);
            }
            this.$emit('search');
        },
        categoryIsToggled(index, category) {
            // Manual toggles always take precedence
            if (typeof this.isToggled[index] !== 'undefined') {
                return this.isToggled[index];
            }
            // Keep open when a filter is chosen
            if (Object.keys(this.filters).length) {
                let match = false;
                forEach(this.filters, (filter) => {
                    forEach(category.topics, (topic) => {
                        if (topic.id === filter.id) {
                            match = true;
                        }
                    });
                });
                return match;
            }
            return false;
        }
    },
    computed: {
        ...mapGetters({ topics: 'topics/topics', filters: 'search/filters' })
    },
    data() {
        return {
            loading: false,
            toggled: false,
            isToggled: []
        };
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
