<template>
    <div class="checkbox">
        <label :for="id">
            <input
                :id="id"
                type="checkbox"
                :name="name"
                :class="className"
                :required="required"
                :disabled="disabled"
                :checked="checked"
                @change="onChange"
            >

            <span class="checkbox__custom" />

            <span v-if="!!$slots.default" class="checkbox__label"><slot /></span>
        </label>
    </div>
</template>

<script>
export default {

    props: {
        id: {
            type: String,
            default() {
                return 'checkbox-id-' + this._uid;
            }
        },

        name: {
            type: String,
            default: null
        },

        trueValue: {
            default: true
        },

        falseValue: {
            default: false
        },

        className: {
            type: String,
            default: null
        },

        required: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return { checked: false };
    },

    created() {
        this.checked = this.$attrs.value === this.trueValue;
    },

    methods: {
        onChange(e) {
            this.checked = e.target.checked;
            this.$emit('input', e.target.checked ? this.trueValue : this.falseValue);
        }
    }
};
</script>
