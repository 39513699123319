<template src="./popups.html" />

<script>
'use strict';

import { mapGetters } from 'vuex';
import popup from '../popup/popup.vue';

export default {
    components: { popup },
    computed: {
        ...mapGetters({ items: 'popup/popups' })
    }
};

</script>
