<template src="./learn.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import infiniteScroll from 'vue-infinite-scroll';
import loader from '../../../patterns/atoms/loader/loader.vue';
import methodTeaser from '../../../patterns/organisms/method-teaser/method-teaser.vue';
import search from '../../../patterns/molecules/search/search.vue';
import topicPicker from '../../../patterns/organisms/topic-picker/topic-picker.vue';

export default {
    methods: {
        ...mapActions({ append: 'methods/paginateSearch', search: 'methods/search' }),
        fetchData() {
            this.loading = true;
            this.search(1).then(() => {
                this.loading = false;
            });
        },
        loadMore() {
            this.loadingMore = true;
            this.append(this.currentPage + 1).then(() => {
                this.loadingMore = false;
            });
        }
    },
    computed: {
        ...mapGetters({ methods: 'methods/methods', currentPage: 'methods/currentPage', lastPage: 'methods/pageCount' }),
        disableInfiniteScroll() {
            return this.loading || this.loadingMore || this.currentPage === this.lastPage;
        }
    },
    components: {
        methodTeaser, loader, topicPicker, search
    },
    directives: { infiniteScroll },
    data() {
        return {
            loading: false,
            loadingMore: false,
            filters: {}
        };
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
