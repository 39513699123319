<template src="./help.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import loader from '../../atoms/loader/loader.vue';

export default {
    components: { loader },
    methods: {
        ...mapActions({ getPage: 'page/getBySlug' }),
        fetchData() {
            this.loading = true;
            this.getPage('help').then(() => {
                this.loading = false;
            }).catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
        }
    },
    computed: {
        ...mapGetters({ page: 'page/page' })
    },
    data() {
        return {
            loading: false
        };
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
