<template src="./forgot-password-form.html" />

<script>
'use strict';

import { mapActions } from 'vuex';
import alert from '../../atoms/alert/alert.vue';
import backButton from '../../atoms/back-button/back-button.vue';
import authApi from '../../../assets/js/api/auth.js';

export default {
    components: { alert, backButton },
    methods: {
        ...mapActions({ addToast: 'toast/add' }),

        submitEmail() {
            this.$validator.validateAll().then((result) => {
                this.loading = true;
                if (result) {
                    authApi.requestPasswordLink(this.email).then((response) => {
                        this.done = true;
                        this.loading = false;
                        this.alert.type = 'success';
                        this.alert.message = response.data.message;
                    }).catch(() => {
                        this.done = false;
                        this.loading = false;
                        this.alert.type = 'error';
                        this.alert.message = 'Something went wrong, please try again.';
                    });
                }
            });
        }
    },
    props: [],
    data() {
        return {
            alert: {
                type: null,
                message: null
            },
            done: false,
            loading: false,
            email: null
        };
    },
    created() {

    }
};

</script>
