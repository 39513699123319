'use strict';

import Vue from 'vue';
import config from '../../config/config.js';
import apiCalls from '../../api/method.js';
import { objectKeysToCamelCase } from '../helpers/convertCase';

// The initial state
const State = {
    method: {
        id: null,
        image: null,
        userId: null,
        type: null,
        title: null,
        slug: null,
        content: null,
        status: 'published',
        createdAt: null,
        updatedAt: null,
        author: {},
        comments: [],
        files: []
    },
    commentPagination: {
        currentPage: 0,
        pageCount: 1,
        totalItems: 0
    }
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    method: ({ method }) => method,
    // eslint-disable-next-line no-empty-pattern
    image: ({}, getters, rootState) => (id, size = 'teaser') => {
        return `${Vue.axios.defaults.baseURL}images/method/${id}/image/${size}?token=${rootState.auth.accessToken}&api_key=${config.api.apikey}`;
    },
    comments: ({ method }) => method.comments,
    totalComments: ({ commentPagination }) => commentPagination.totalItems,
    commentsCurrentPage: ({ commentPagination }) => commentPagination.currentPage,
    commentsPageCount: ({ commentPagination }) => commentPagination.pageCount
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    get({ commit }, id) {
        return apiCalls.get(id).then((response) => {
            commit('populate', response.data);
        });
    },
    getComments({ commit }, { parent: method, pageNum }) {
        return apiCalls.getComments(method, pageNum).then((response) => {
            commit('populateComments', response.data.data);
            commit('setCurrentPage', response.data.current_page);
            commit('setPageCount', response.data.last_page);
            commit('setTotalItems', response.data.total);
        });
    },
    addComment({ commit }, { comment, parent: method }) {
        return apiCalls.addComment(comment, method).then((response) => {
            commit('addComment', response.data);
        });
    }
};

// Mutations change the actual data in the state
const Mutations = {
    populate(state, method) {
        state.method = objectKeysToCamelCase(method);
        Vue.set(state.method, 'comments', []);
    },
    populateComments(state, comments) {
        // Reactivity workaround
        comments.forEach((comment) => {
            state.method.comments.push(objectKeysToCamelCase(comment));
        });
    },
    addComment(state, comment) {
        state.method.comments.unshift(objectKeysToCamelCase(comment));
    },
    setCurrentPage(state, page) {
        state.commentPagination.currentPage = page;
    },
    setPageCount(state, count) {
        state.commentPagination.pageCount = count;
    },
    setTotalItems(state, count) {
        state.commentPagination.totalItems = count;
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
