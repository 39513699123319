<template src="./profile.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import loader from '../../../patterns/atoms/loader/loader.vue';
import backButton from '../../atoms/back-button/back-button.vue';
import avatar from '../../atoms/avatar/avatar.vue';
import topicList from '../../molecules/topic-list/topic-list.vue';
import userRecentPosts from '../../organisms/user-recent-posts/user-recent-posts.vue';

export default {
    components: { loader, backButton, topicList, avatar, userRecentPosts },
    methods: {
        ...mapActions({ getUser: 'user/get' }),
        fetchData() {
            this.loading = true;
            return this.getUser(this.id).then(() => {
                this.loading = false;
            }).catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
        },
        trackSocial(type) {
            this.$gtm.trackEvent({
                category: 'social',
                action: 'click',
                label: type
            });
        }
    },
    computed: {
        ...mapGetters({ user: 'user/user' }),
        location() {
            let location = '';
            if (this.user.city) {
                location = this.user.city;
                if (this.user.country) {
                    location += ', ';
                }
            }
            if (this.user.country) {
                location += this.user.country;
            }
            return location;
        }
    },
    props: ['id'],
    data() {
        return { loading: false };
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData'
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    }
};
</script>
