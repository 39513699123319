<template src="./comment-form.html" />

<script>
'use strict';

import { mapGetters } from 'vuex';
import Avatar from '../../atoms/avatar/avatar.vue';
import Alert from '../../atoms/alert/alert.vue';
import commentBalloon from '../../atoms/comment-balloon/comment-balloon.vue';

export default {
    components: { Avatar, Alert, commentBalloon },
    methods: {
        doAddComment() {
            this.loading = true;
            this.$store.dispatch(
                `${this.module}/addComment`,
                { comment: this.comment, parent: this.parent }
            ).then(() => {
                this.loading = false;
                this.commentPosted = true;
                this.$gtm.trackEvent({
                    category: 'comment',
                    action: 'new-comment',
                    label: 'New comment'
                });
            });
        }
    },
    computed: {
        ...mapGetters({ user: 'auth/me' })
    },
    props: {
        parent: {
            type: Object,
            default: () => {}
        },
        module: {
            type: String,
            default: ''
        },
        commentsCount: {
            default: 0
        }
    },
    data() {
        return {
            comment: {
                content: ''
            },
            commentPosted: false,
            loading: false
        };
    }
};
</script>
