'use strict';

import Vue from 'vue';

// The initial state
const State = {
    search: '',
    filters: {}
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    search: ({ search }) => search,
    filters: ({ filters }) => filters
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    setSearch({ commit }, search) {
        commit('setSearch', search);
    },
    setFilters({ commit }, search) {
        commit('setFilters', search);
    },
    addFilter({ commit }, filter) {
        commit('addFilter', filter);
    },
    removeFilter({ commit }, filter) {
        commit('removeFilter', filter);
    },
    reset({ commit }) {
        commit('reset');
    }
};

// Mutations change the actual data in the state
const Mutations = {
    setSearch(state, search) {
        state.search = search;
    },
    setFilters(state, filters) {
        state.filters = filters;
    },
    removeFilter(state, filter) {
        Vue.delete(state.filters, filter.id);
    },
    addFilter(state, filter) {
        Vue.set(state.filters, filter.id, filter);
    },
    reset(state) {
        state.filters = {};
        state.search = '';
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
