<template src="./popup.html" />

<script>
'use strict';

import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({ doDismiss: 'popup/dismiss' }),
        dismiss() {
            this.doDismiss(this.id);
        }
    },
    props: ['message', 'id']
};

</script>
