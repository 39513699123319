'use strict';

import bugsnag from '@bugsnag/js';
import Vue from 'vue';
import bugsnagVue from '@bugsnag/plugin-vue';
import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import VueGtm from 'vue-gtm';
import App from './App.vue';
import Router from './assets/js/router';
import Store from './assets/js/store';
import Api from './assets/js/api/api';
import config from './assets/js/config/config';

import 'fitie/lib/fitie.js';

const online = process.env.APP_ONLINE;

Vue.use(VueAxios, Api);

const veeValidateConfig = {
    events: 'blur'
};
Vue.use(VeeValidate, veeValidateConfig);

Vue.use(VueGtm, {
    id: config.gtmId, // Your GTM ID
    enabled: process.env.APP_ENV === 'production' || process.env.APP_ENV === 'staging',
    debug: process.env.APP_ENV !== 'production',
    vueRouter: Router
});

/** Bugsnag ALWAYS KEEP FIRST **/
if (process.env.NODE_ENV === 'production') {
    const bugsnagClient = bugsnag({
        apiKey: config.bugsnagId,
        releaseStage: process.env.APP_ENV
    });
    bugsnagClient.use(bugsnagVue, Vue);
}
/** End bugsnag **/

// eslint-disable-next-line no-new
const app = new Vue({
    el: '#app',
    router: online ? Router : undefined,
    store: Store,
    render: h => h(App)
});

/**
 * Register service worker if supported
 */
if (online && 'serviceWorker' in navigator) {
    // Delay registration until after the page has loaded, to ensure that our
    // precaching requests don't degrade the first visit experience.
    // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
    window.addEventListener('load', function() {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // *Don't* register service worker file in, e.g., a scripts/ sub-directory!
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        navigator.serviceWorker.register('/service-worker.js').then(function(reg) {
            // updatefound is fired if service-worker.js changes.
            reg.onupdatefound = function() {
                // The updatefound event implies that reg.installing is set; see
                // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
                const installingWorker = reg.installing;

                installingWorker.onstatechange = function() {
                    switch (installingWorker.state) {
                    case 'installed':
                        if (navigator.serviceWorker.controller) {
                            // At this point, the old content will have been purged and the fresh content will
                            // have been added to the cache.
                            // It's the perfect time to display a "New content is available; please refresh."
                            // message in the page's interface.
                            window.setTimeout(() => {
                                app.$store.dispatch('popup/add', { message: 'There\'s a new version of the app available. <a href="">Reload</a> to see changes.', id: 'new-content' });
                            }, 500);
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a "Content is cached for offline use." message.
                            console.log('Content is now available offline!');
                        }
                        break;

                    case 'redundant':
                        console.error('The installing service worker became redundant.');
                        window.setTimeout(() => {
                            app.$store.dispatch('popup/add', { message: 'There\'s a new version of the app available. <a href="">Reload</a> to see changes.', id: 'new-content' });
                        }, 500);
                        break;
                    }
                };
            };
        }).catch(function(e) {
            console.error('Error during service worker registration:', e);
        });
    });
}
