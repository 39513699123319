<template src="./navigation.html" />

<script>
'use strict';

import { mapGetters } from 'vuex';
import naviAvatar from '../../molecules/navi-avatar/navi-avatar.vue';

export default {
    components: { naviAvatar },
    computed: {
        ...mapGetters({ authorized: 'auth/authorized' })
    },
    methods: {
        toggleMenu() {
            this.navToggled = !this.navToggled;
        }
    },
    data() {
        return {
            navToggled: false,
            navigationItems: [
                {
                    label: 'Explore',
                    route: 'explore'
                },
                {
                    label: 'Learn',
                    route: 'learn'
                },
                {
                    label: 'Network',
                    route: 'network'
                },
                {
                    label: 'Help',
                    route: 'help'
                }
            ]
        };
    }
};
</script>
