<template src="./cookie-bar.html" />

<script>
'use strict';

import Cookie from 'js-cookie';

export default {
    data() {
        return { accepted: null };
    },
    computed: {
        cookiesAccepted() {
            if (!Cookie.get('cookie-consent')) {
                return this.accepted;
            }
            return Cookie.get('cookie-consent');
        }
    },
    methods: {
        accept() {
            Cookie.set('cookie-consent', true, { expires: 365 });
            this.accepted = true;
            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({ event: 'cookies-accepted' });
            }
        }
    }
};
</script>
