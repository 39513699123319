<template src="./user-recent-posts.html" />

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import loader from '../../atoms/loader/loader.vue';
import postTeaser from '../post-teaser/post-teaser.vue';

export default {
    components: {
        postTeaser, loader
    },
    methods: {
        ...mapActions({ getPosts: 'posts/getPostsForUser' }),
        fetchData() {
            this.loading = true;
            this.getPosts(this.user).then(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapGetters({ posts: 'posts/posts' })
    },
    created() {
        this.fetchData();
    },
    props: ['user'],
    data() {
        return { loading: false };
    }
};
</script>
