'use strict';

import Vue from 'vue';
import Cookie from 'js-cookie';

import formatDate from 'date-fns/format';
import compareAsc from 'date-fns/compare_asc';
import { isNil } from 'lodash';
import router from '../../router.js';

import userApiCalls from '../../api/user.js';
import apiCalls from '../../api/auth.js';
import config from '../../config/config.js';
import { objectKeysToCamelCase } from '../helpers/convertCase';
import { State as userState } from './user.js';

// The initial state
const State = {
    accessToken: null,
    expiresAt: null,
    authorized: false,
    me: { ...userState.user }
};

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
const Getters = {
    accessToken: ({ accessToken }) => accessToken,
    expiresAt: ({ expiresAt }) => expiresAt,
    authorized: ({ authorized }) => authorized,
    me: ({ me }) => me,
    complete: ({ me }) => me && me.profileComplete ? me.profileComplete : false
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
const Actions = {
    login({ commit, dispatch }, { emailAddress, password }) {
        return apiCalls.login(emailAddress, password).then((response) => {
            const decodedJwt = JSON.parse(atob(response.data.access_token.split('.')[1]));
            const auth = {
                accessToken: response.data.access_token,
                expiresAt: formatDate(new Date(decodedJwt.exp * 1000))
            };
            commit('setAccessToken', auth);

            return dispatch('getCurrentUser').then(() => {
                commit('authorize');
            });
        });
    },
    logout({ commit }) {
        return apiCalls.logout().then(() => {
            commit('deauthorize');
        }).catch(() => {
            commit('deauthorize');
        });
    },
    check({ commit, dispatch, getters: { expiresAt = null, accessToken = null } }) {
        if (isNil(expiresAt) || isNil(accessToken)) {
            commit('deauthorize');
            router.push('/');
        } else if (compareAsc(new Date(), new Date(expiresAt)) > -1) {
            return dispatch('refresh');
        } else {
            commit('authorize');
            return Promise.resolve();
        }
    },
    refresh({ commit }) {
        return apiCalls.refresh().then((response) => {
            const decodedJwt = JSON.parse(atob(response.data.access_token.split('.')[1]));
            const auth = {
                accessToken: response.data.access_token,
                expiresAt: formatDate(new Date(decodedJwt.exp * 1000))
            };
            commit('authorize');
            commit('setAccessToken', auth);
            return Promise.resolve(auth);
        }).catch(() => {
            commit('deauthorize');
            router.push('/');
        });
    },
    getCurrentUser({ commit }) {
        return userApiCalls.me().then((response) => {
            commit('setCurrentUser', response.data);
        });
    },
    setImage({ commit }, image) {
        commit('setImage', image);
    }
};

// Mutations change the actual data in the state
const Mutations = {
    authorize(state) {
        state.authorized = true;
    },
    setAccessToken(state, { accessToken, expiresAt }) {
        state.accessToken = accessToken;
        state.expiresAt = expiresAt;
        Cookie.set('token', accessToken, { domain: config.self.domain, expires: 14, secure: true });

        Vue.axios.defaults.headers.common = Object.assign({}, Vue.axios.defaults.headers.common, {
            Authorization: `Bearer ${accessToken}`
        });
    },
    deauthorize(state) {
        state.accessToken = null;
        state.expiresAt = null;
        Cookie.remove('token');

        Vue.axios.defaults.headers.common = Object.assign({}, Vue.axios.defaults.headers.common, {
            Authorization: null
        });

        state.authorized = false;
        state.me = null;
    },
    setCurrentUser(state, user) {
        state.me = objectKeysToCamelCase(user);
    },
    setImage(state, image) {
        Vue.set(state.me, 'image', image);
    }
};

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
